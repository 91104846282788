const baseUrl = "https://dalarna-admin.hpvelimination.se/api/";

const login = baseUrl + "auth";

const activeGroupQuestionnaire = baseUrl + "group_questionnaire/active";
const activeGroupQuestionnaire1 =
  baseUrl + "group_questionnaire/active/NeedToAskFirst";
const activeGroupQuestionnaire2 =
  baseUrl + "group_questionnaire/active/Cancer Screening Group";
const unansweredQuestionByQuestionnaire =
  baseUrl + "question/unanswered_by_questionnaire";
const saveAnswer = baseUrl + "answer";
const finishAnswer = baseUrl + "group_questionnaire_answer_finished";
const registerUser = baseUrl + "public/sign_up/";
const consentText = baseUrl + "public/get_consent";
const firstPageText = baseUrl + "public/get_first_page_text";
const welcomeThankYouText = baseUrl + "public/welcome_thank_you";
const registrationUrl = baseUrl + "public/registration_url";
const registration = baseUrl + "public/registration";
const vaccineHistory = baseUrl + "nurse_patient/by_patient";
const regions = baseUrl + "public/region";
const sites = baseUrl + "public/site";
const registerUserPersonNumber =
  baseUrl + "public/register_user_person_number/";

export {
  baseUrl,
  login,
  activeGroupQuestionnaire,
  activeGroupQuestionnaire1,
  activeGroupQuestionnaire2,
  unansweredQuestionByQuestionnaire,
  saveAnswer,
  finishAnswer,
  registerUser,
  consentText,
  firstPageText,
  welcomeThankYouText,
  registrationUrl,
  registration,
  vaccineHistory,
  regions,
  sites,
  registerUserPersonNumber,
};
